.display-pin-container {
  height: 75vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15vh;
}


.display-pin {
  width: fit-content;
  text-align: center;
}

.display-pin-label {
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 28px;
}

.display-carousel-container-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.display-carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.display-carousel{
  width: 80vw;
}