.load-image {
  width: 50px;
}

.load-container {
  background-color: white;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  padding: 0px;
}
