.modal-add-edit-body-button {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 3px;
}

.modal-delete-screen-button-red {
  margin-top: 1rem !important;
  background-color: #d32f2f !important;
}

.modal-delete-screen-span {
  font-weight: bold;
}

.modal-delete-screen-form {
  display: inline !important;
}
