.login-container {
  height: 100vh;
  margin: 0px !important;
}

.login-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-logo-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

.login-logo-image {
  border-radius: 50px;
}

.login-form {
  display: flex;
  justify-content: center;
}

.login-form-container {
  max-width: 500px;
  padding: 2rem 2.5rem 2rem 2.5rem !important;
}

.login-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0.4rem 0px 0rem 5px;
}

.login-button {
  width: 100%;
}
.login-form-grid {
  display: flex;
  align-items: center;
}
