.card-container {
  height: 98%;
  width: 100%;
  margin: 1rem 1rem 1rem 0rem;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.card-image {
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
}
.card-body {
  width: 100%;
  text-align: left;
}
.card-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.card-text-title {
  text-transform: uppercase;
  background-color: #004e91;
}
