.navbar {
  background-color: #004e91;
}

.navbar-container {
  max-width: 100%;
  padding: 0rem 1rem 0rem 2rem;
}

.card-text-title.card-header,
.card-body {
  background-color: white;
}

nav.navbar {
  box-shadow: 0px 0px 5px 0px black;
}

span.navbar-brand {
  margin-right: 5rem;
  margin-left: 1rem;
  font-weight: bold;
}

.navbar-expand-lg .navbar-nav a.link.nav-link {
  padding: 0.56em 1.8rem 0.6rem 1.8rem;
}
.link-unstyled {
  color: inherit;
  text-decoration: none;
}

.navbar-collapse {
  margin-left: 1rem;
}

.nav-exit-container {
  display: flex;
}

.nav-icon {
  margin-left: 0.2rem;
}
