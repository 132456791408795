.modal-delete-settings-button-red {
  margin-top: 1rem !important;
  background-color: #d32f2f !important;
}

.modal-delete-settings-span {
  font-weight: bold;
}

.modal-delete-settings-form {
  display: inline !important;
}

.modal-add-edit-body-select {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 3px;
}

.modal-add-edit-body-button {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 3px;
}
