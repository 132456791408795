.settings-container {
  padding-top: 2rem;
}

.settings-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem !important;
}

.settings-button {
  max-width: 20rem;
  margin-top: 2rem !important;
}

.settings-delete-icon-container,
.settings-edit-icon-container,
.settings-setting-icon-container {
  display: flex;
  cursor: pointer;
}

.settings-setting-icon-container {
  margin-right: 1rem;
  color: black;
}

.settings-edit-icon-container {
  color: #004e91;
}

.settings-delete-icon-container {
  color: #d32f2f;
}

.settings-setting-icon-text,
.settings-edit-icon-text,
.settings-delete-icon-text {
  display: none;
}

.settings-icons-container {
  display: flex;
}

.settings-delete-icon-width {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
}

.settings-delete-icon-container:hover .settings-delete-icon-text,
.settings-edit-icon-container:hover .settings-edit-icon-text,
.settings-setting-icon-container:hover .settings-setting-icon-text {
  display: block;
}

.link-unstyled {
  color: inherit;
  text-decoration: none;
}

.settings-cards-container {
  margin-top: 1rem;
}

.settings-link-screen {
  margin-left: 0.2rem;
}

.settings-carousel-container {
  width: auto;
  height: auto;
}
