.screens-container {
  padding-top: 2rem;
}

.screens-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem !important;
}

.screens-button {
  max-width: 15rem;
}

.screen-delete-icon-container,
.screen-edit-icon-container,
.screen-setting-icon-container {
  display: flex;
  cursor: pointer;
}

.screen-setting-icon-container {
  margin-right: 1rem;
  color: black;
}

.screen-edit-icon-container {
  color: #004e91;
}

.screen-delete-icon-container {
  color: #d32f2f;
}


.screen-setting-icon-text,
.screen-edit-icon-text,
.screen-delete-icon-text {
  display: none;
}

.screen-icons-container {
  display: flex;
}

.screen-delete-icon-width {
  width: 90%;
  display: flex;
  justify-content: flex-end !important;
}

.screen-delete-icon-container:hover .screen-delete-icon-text,
.screen-edit-icon-container:hover .screen-edit-icon-text,
.screen-setting-icon-container:hover .screen-setting-icon-text {
  display: block;
}

.link-unstyled {
  color: inherit;
  text-decoration: none;
}

.screens-cards-container{
  margin-top: 1rem;
}