.input-outlined-container{
    height: 50px;
}

.input-container{
    width: 100%;
    margin: 5px !important;
}

.input-form{
    display: contents;
}